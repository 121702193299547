import "./about.css"
import RCALogo from "../media/logo-web-transparent-new.png"
import {Container, Row, Col} from "react-bootstrap";

export default function About(){
    return(
        <Container id={"about"} className={"Container_about"} fluid>
            <Row>
                <Col lg={6} md={12} className={"Col_aboutLogo"}>
                    <img src={RCALogo} alt="" className={"img_about"}/>
                </Col>
                <Col className={"Col_about"}>
                    <h1  style={{ fontWeight: 200 }}>Meet <span style={{ fontWeight: 500 }}>RCA Technology</span></h1>
                    <p>Founded in 1997, RCA Technology has been a trusted provider of software applications for over two
                        decades.
                        <br/><br/> We are guided by years of experience in delivering Enterprise Resource Planning (ERP)
                        solutions and have
                        been dedicated to empowering businesses of all sizes to streamline their operations, enhance
                        productivity,
                        and achieve sustainable growth.
                        <br/><br/><span style={{color: '#f7c105'}}>Our Mission</span>
                        <br/>"Provide reliable and resilient products that consistently exceed our clients' expectations"
                        <br/><br/><span style={{color: '#f7c105'}}>Our Vision</span>
                        <br/>"To be at the forefront of top quality software development companies, with an
                        optimized development process using reliable technologies and innovation"
                    </p>
                </Col>
            </Row>
        </Container>
    )
        ;
}